import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Contact from "../components/contact"
import OfferLayout from "../components/offerLayout"
import lista from "../content/oferta/angielskie.yaml"
import image from "../images/oferta/angielskie.jpeg"

const Oferta = () => (
  <Layout>
    <Seo title="Oferta" />
    <div className="container py-20">
      <h1 className="font-bold text-4xl text-secondary mb-4" style={{ marginLeft: '-5px'}}>Oferta</h1>
      <div className="border-b-8 border-secondary w-12 mb-12"></div>
       <OfferLayout title="Pędzle Angielskie" list={lista} image={image}>
       </OfferLayout>
    </div>
    <Contact greyBackground={true} />
  </Layout>
)

export default Oferta
